import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Header from "../components/header"
import Footer from "../components/footer"
import TextColumnsBlock from "../components/blocks/TextColumnsBlock"
import BoxColumn from "../components/blocks/ColumnsBlock"


const IndexPage = ({ data }) => {
  const HomepageInfo = data.HomepageInfo.edges[0].node
  return(<Layout page="page-home">
    <Seo title="Homepage" />
    <div className="home-hero-wrap">
      <Header />
        <div className="main-col-one">
          {HomepageInfo.hero_title && <h1 dangerouslySetInnerHTML={{ __html: HomepageInfo.hero_title }}></h1>}
          {HomepageInfo.hero_subtitle && <h2 className="hero-subtitle" dangerouslySetInnerHTML={{ __html: HomepageInfo.hero_subtitle }}></h2>}
          {HomepageInfo.hero_description && <h3 className="hero-description" dangerouslySetInnerHTML={{ __html: HomepageInfo.hero_description }}></h3>}
        </div>
    </div>
    <div className="home-body">
      {HomepageInfo.content.map((contentBlock, key) => {
        if (contentBlock.strapi_component === "blocks.box-columns") {
          return (
            <div className="box">
              <BoxColumn content={contentBlock} key={key} />
            </div>
          )
        }
        else if (contentBlock.strapi_component === "blocks.text-columns") {
          return (
            <div className="text-col">
              <TextColumnsBlock content={contentBlock} key={key} />
            </div>
          )
        }
        return null;
      })}
     <Footer />
    </div>
  </Layout>
 
  )
}

export default IndexPage

export const pageQuery = graphql`
{
  HomepageInfo: allStrapiHomepage {
   edges {
      node {
        hero_title
        hero_subtitle
        hero_description
        content
      }
    }
  }
}
`
